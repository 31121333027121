<template>
  <div class="header container">
    <router-link to="/" class="header__logo">
      <img src="/images/logo.png" alt="logo" />
    </router-link>

    <div class="header__nav">
      <div
        v-if="$i18n.locale == 'de'"
        @click="switchLanguage('en', true)"
        class="header__lang"
      >
        <div class="header__lang-icon">
          <deLang />
        </div>
      </div>
      <div
        v-if="$i18n.locale == 'en'"
        @click="switchLanguage('de', true)"
        class="header__lang"
      >
        <div class="header__lang-icon">
          <enLang />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import enLang from "~/assets/images/logo/en-lang.svg";
import deLang from "~/assets/images/logo/de-lang.svg";

export default {
  name: "Header",
  components: {
    enLang,
    deLang,
  },

  created() {
    let savedLanguage;
    if (this.$route.params.lang) {
      savedLanguage = this.$route.params.lang;
    } else {
      savedLanguage = localStorage.getItem("language") || "de";
    }
    this.switchLanguage(savedLanguage);
  },

  mounted() {
    console.log(this.$i18n.locale);
  },

  methods: {
    switchLanguage(lang, reload = false) {
      this.currentLanguage = lang;
      this.$i18n.locale = lang;
      localStorage.setItem("language", lang); // Сохраняем язык
      if (reload) {
        location.reload();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
  position: relative;

  &__logo {
    display: flex;
    align-items: center;
    font-size: 24px;
    font-weight: 700;

    img {
      margin-right: 10px;
      height: 40px;
    }
  }

  &__nav {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(0, -50%);
    display: flex;
    align-items: center;
    height: fit-content;
  }

  &__lang {
    padding: 10px;
    border-radius: 8px;
    border: 1px solid #e5e7eb;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.15);
    height: fit-content;
    cursor: pointer;
  }

  &__lang-icon {
    height: 20px;
  }

  &__contact {
    margin-left: 10px;
    padding: 11px 15px;
    border-radius: 8px;
    background: #191919;
    color: #fff;
    font-size: 16px;
    font-weight: 500;
    height: fit-content;
    cursor: pointer;
  }

  @media (max-width: 991px) {
    justify-content: space-between;

    &__nav {
      position: static;
      transform: none;
    }
  }

  @media (max-width: 568px) {
    &__logo {
      img {
        width: 40px;
        height: 40px;
      }
    }
  }
}
</style>
